import { type SortingOrder } from '@vakantiesnl/types/src/search';

import { type FilterState } from './interfaces';

/** Search related config */
export const DEFAULT_SEARCH_RESULTS_SIZE = 15;
export const DEFAULT_SORT_ORDER_OPTION = 'popularity_desc';
export const DEFAULT_TRANSPORT_OPTION = 'INCLUDING_FLIGHT';

export const STAR_OPTIONS = ['1', '2', '3', '4', '5'] as const;

export const SORT_ORDER_OPTIONS: SortingOrder[] = [
	'price',
	'price_desc',
	'price_quality',
	'hotel_review_rating',
	'popularity_desc',
];

export const initialFilterState: Readonly<FilterState> = {
	departureDate: null,
	priceMin: null,
	priceMax: null,
	flexibleDepartureDate: 0,
	sort: DEFAULT_SORT_ORDER_OPTION,
	accoType: [],
	facilities: [],
	stars: [],
	awards: [],
	distances: [],
	pool: [],
	zooverAwards: [],
	mealplans: [],
	departureAirport: [],
	transport: DEFAULT_TRANSPORT_OPTION,
	themes: [],
	attributes: [],
	// todo: WEB-5634 geo slugs should be removed and use the countries/regions/cities objects instead
	countrySlugs: [],
	regionSlugs: [],
	citySlugs: [],
	rating: '',
	size: DEFAULT_SEARCH_RESULTS_SIZE,
	page: 1,
	isDealsRoute: false,
	campaign: '',
	countries: [],
	regions: [],
	cities: [],
	giata_ids: null,
	chains: [],
	chainsSlugs: [],
	tourOperators: [],
	tourOperatorsSlugs: [],
	airlines: [],
	flightProvider: '',
};

/** This const will add the count for filters and decides which filters to show in a pill */
export const FILTER_KEYS_TO_COUNT = [
	'accoType',
	'priceMin',
	'priceMax',
	'facilities',
	'distances',
	'rating',
	'stars',
	'mealplans',
	'transport',
	'themes',
	'attributes',
	'durations',
	'countrySlugs',
	'regionSlugs',
	'citySlugs',
	'departureAirport',
	'awards',
	'chainsSlugs',
	'tourOperatorsSlugs',
	'airlines',
	'flightProvider',
] as const;

/** Used to target specific geo filters to remove or clear them */
export const GEO_SLUGS_FILTER_KEYS = ['countrySlugs', 'regionSlugs', 'citySlugs'];
export const GEO_FILTER_KEYS = ['countries', 'regions', 'cities'];
